<template>
  <div>
    <Progress v-if="loading" />
    <v-card tile v-if="!loading">
      <v-card-text class="text-center">
        <v-container>
         <v-row justify="center" align="center">
            <v-col cols="10">
               <v-row>
                <v-col cols="3">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
                 </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="go('expense', 'expenses',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="go('expense', 'expenses',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go('expense', 'expenses',`branch_id=${branch_id}&from=${from}&to=${to}`)"
                  ></v-select>
                  </v-col>
                </v-row>
            </v-col>

            <v-col cols="2" align="right">
              <v-btn color="primary" medium tile elevation="1" @click="add()">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="expenses"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
        >
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="edit(item)" color="success">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>

        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-card-text>
    </v-card>

    <formInput
      v-if="dialog_form"
      v-on:go="go"
      v-on:close="close"
      :user_edit="user_edit"
    />
    <dialogDelete
      :dialog="dialog_delete"
      data="expense"
      v-on:close="close"
      v-on:deleteAction="deleteAction"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Mixins from "./mixin";
import { mapGetters } from "vuex";
export default {
  mixins: [Mixins],
  computed: {
    ...mapGetters({
      expenses: "data/expenses"
    })
  },
  async created() {
    this.branchs = this.user.branchs

     let date = new Date()
    let bln =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
    let year = date.getFullYear()
    this.from = new Date(`${year}-${bln}-01`).toISOString().substr(0, 10)
    if (this.expenses.length) {
      this.loading = false;
    }
    this.params = `branch_id=${this.branch_id}&from=${this.from}&to=${this.to}`

  },
  async mounted() {
    await this.go("expense", "expenses",this.params);
  }
};
</script>
